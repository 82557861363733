import React from 'react'

import ViewContainer from 'common/components/ViewContainer'
import TransactionsTable from 'core/TransactionsTable'
import { Role } from 'common/store/auth/slice'

interface TransactionsProps {
    organizationName: string,
    role: Role
}

const Transactions: React.FC<TransactionsProps> = ({ organizationName, role }) => {
  const url = `/me/transactions`
  return (
    <ViewContainer title='Transactions'>
      <TransactionsTable role={role} fetchUrl={url} displayContainer organizationName={organizationName}/>
    </ViewContainer>
  )
}

export default Transactions
