import React, { useState } from 'react'
import Icon from 'antd/lib/icon'
import { Modal } from 'antd'
import { Button } from 'common/components/Button'
import { ContentContainer, StyledIcon } from './styledComponents'
import AddProductForm from './AddBrandForm'
import { BrandCountries } from 'common/store/schema/app'

type AddProductBasedOnTypeProps = {
  brandCountryList : Array<BrandCountries>,
  brandListType: string,
  addProductToTheList : (updatedValue: any) => void
}

const AddProductBasedOnType: React.FC<AddProductBasedOnTypeProps> = ({ brandCountryList, brandListType, addProductToTheList }) => {
  const [openModal, setOpenModal] = useState(false)

  const buttonName = (brandListType === "exclusion") ? "Add Product to Exclusion List" : "Add Product to Persistent List"

  return (
    <>
      <div>
        <Button
          type='primary'
          shape='round'
          style={{ margin: '5px 0' }}
          onClick={() => setOpenModal(true)}
        >
          <Icon type='plus' />
          {buttonName}
        </Button>
      </div>
      <Modal
        visible={openModal}
        footer={null}
        onCancel={() => setOpenModal(false)}
        width={500}
      >
        <ContentContainer>
          <StyledIcon type='plus' />
          
          <AddProductForm
            setOpenModal={setOpenModal}
            addProductToTheList = {addProductToTheList}
            brandCountryList = {brandCountryList}
            brandListType = {brandListType}
          />
        </ContentContainer>
      </Modal>
    </>
  )
}

export default AddProductBasedOnType
