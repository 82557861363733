import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { State } from 'common/store/rootReducer'
import {
  getToken,
  validateToken,
  getTokenExpiration
} from 'common/utils/common'

export type Role = 'partner_admin' | 'super_admin' | 'partner_user' | ''

type AuthenticationState = {
  token: string
  tokenExpiration: number
  needsPasswordConfirm: boolean
  loading: boolean
  error: string | null
  email: string
  role: Role
  organizationName: string | null
  reconcilePartner: boolean | null
}

type LoginPayload = {
  token: string
  tokenExpiration: number
}

type UserInfoPayload = {
  email: string
  role: Role
  organizationName: string | null
  reconcilePartner: null | boolean
}

const token = getToken()

let initialState: AuthenticationState = {
  token: validateToken(token),
  tokenExpiration: getTokenExpiration(token),
  needsPasswordConfirm: false,
  loading: false,
  error: null,
  email: '',
  role: '',
  organizationName: null,
  reconcilePartner: null
}

function startLoading(state: AuthenticationState) {
  state.loading = true
  state.error = null
}

function loadingFailed(
  state: AuthenticationState,
  action: PayloadAction<string>
) {
  state.loading = false
  state.error = action.payload
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logoutRequest(state) {
      state.email = ''
      state.token = ''
      state.tokenExpiration = 0
    },
    loginRequest: startLoading,
    loginSuccess(state, action: PayloadAction<LoginPayload>) {
      state.loading = false
      state.token = action.payload.token
      state.tokenExpiration = action.payload.tokenExpiration
      state.error = null
    },
    loginFailure: loadingFailed,
    resetPasswordRequest: startLoading,
    resetPasswordSuccess(state) {
      state.loading = false
      state.error = null
      state.needsPasswordConfirm = true
    },
    resetPasswordFailure: loadingFailed,
    confirmPasswordRequest: startLoading,
    confirmPasswordSuccess(state) {
      state.loading = false
      state.error = null
      state.needsPasswordConfirm = false
    },
    confirmPasswordFailure: loadingFailed,
    changeTemporaryPasswordRequest: startLoading,
    changeTemporaryPasswordSuccess(state) {
      state.loading = false
      state.error = null
    },
    changeTemporaryPasswordFailure: loadingFailed,
    userInfoRequest: startLoading,
    userInfoSuccess(state, action: PayloadAction<UserInfoPayload>) {
      state.loading = false
      state.error = null
      state.email = action.payload.email
      state.role = action.payload.role
      state.organizationName = action.payload.organizationName
      state.reconcilePartner = action.payload.reconcilePartner
    },
    userInfoFailure: loadingFailed
  }
})

export const authSelectors = {
  getToken: (state: State) => state.auth.token,
  getError: (state: State) => state.auth.error,
  getActiveUsername: (state: State) => state.auth.email,
  isAuthenticated: (state: State) => Boolean(validateToken(state.auth.token))
}

export const {
  loginRequest,
  loginSuccess,
  loginFailure,
  resetPasswordRequest,
  resetPasswordSuccess,
  resetPasswordFailure,
  confirmPasswordRequest,
  confirmPasswordSuccess,
  confirmPasswordFailure,
  changeTemporaryPasswordFailure,
  changeTemporaryPasswordRequest,
  changeTemporaryPasswordSuccess,
  userInfoFailure,
  userInfoRequest,
  userInfoSuccess,
  logoutRequest
} = authSlice.actions

export const authReducer = authSlice.reducer
