import React, { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import Icon from 'antd/lib/icon'
import { Filters } from './styledComponents'
import { Button } from 'common/components/Button'
import PaginatedTable from 'common/components/PaginatedTable'
import useDownloadCSV from 'common/hooks/useDownloadCSV'
import { Brands } from 'common/store/schema/brands';
import {
  CatalogContainer,
  SectionHeader,
  SectionTitle,
} from './styledComponents'
import { ContentContainer } from 'common/components/ContentContainer'
import { DateTime } from 'luxon'
import { DatePicker } from 'common/components/DatePicker'
import Moment from 'moment';
import { Input } from 'common/components/Input'


interface CatalogTableProps {
  fetchBrands: (currentPage: number, perPage: number,
    sortBy: string, order: string, fromDate: string, toDate: string,
    filterByProductId: string, filterByProductName: string, filterByStatus: string) => void,
  brands: Brands[]
  totalCount: number
}
const defaultFromDate = DateTime.utc().minus({ days: 90 }).toString() // .toFormat('YYYY-MM-DD')
const defaultToDate = DateTime.utc().toString()
const CatalogAudit : React.FC<CatalogTableProps & RouteComponentProps> = ({fetchBrands, brands, totalCount}) => {
  console.log('brands1', brands);

  const [fromDate, setFromDate] = useState(defaultFromDate)
  const [toDate, setToDate] = useState(defaultToDate)

  const fetchUrl = `/catalog-updates`
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPage] = useState(10)
  const [sortBy, setSortBy] = useState("created_at")
  const [order, setOrder] = useState("DESC")
  const [filterByProductId, setFilterByProductId] = useState("")
  const [filterByProductName, setfilterByProductName] = useState("")
  const [filterByStatus, setFilterByStatus] = useState("")
  
  const dateFormat = 'YYYY-MM-DD'

  //Disable the date fromDate which is less than equal to today's date
  const disabledStartDate = (value: Moment.Moment | null) =>{
    const startDate = Moment(value).utc().startOf('day').format()   
    return  startDate > Moment.utc().endOf('day').format();
  };

  //Disable the date toDate which is less than start date or greater than today's date
  const disabledDate = (value: Moment.Moment | null) =>{
    const endDate = Moment(value).utc().startOf('day').format()
   // Can not select days before today and today
   return  endDate < fromDate || endDate > Moment.utc().endOf('day').format();
  }; 


  useEffect(() => {
    //Fetch all the brands
    fetchBrands(currentPage,pageSize,sortBy,order,fromDate,toDate,filterByProductId,filterByProductName,filterByStatus)
  }, [fetchBrands,currentPage,pageSize,sortBy,order,fromDate,toDate,filterByProductId,filterByProductName,filterByStatus])



  const handlePageChange = (pageNumber: number) => setCurrentPage(pageNumber)
  const handlePageSizeChange = (perPage: number) => {
    setCurrentPage(1)
    setPage(perPage)
  }

  const onSortChange = (sortBy: string, order: string | null) => {
    setSortBy(sortBy)

    if(order)
      setOrder(order)
  }

  const onFilterApplied = (filterField: string | null, filterValue: string | null) => {
    if(filterField)  
      onSearched(filterField, filterValue)
  }

  const onSearched = (field:string, value: string | null) => {
    if(field === "productId")
      setFilterByProductId(value ? value : "");
    else if(field === "productName")
      setfilterByProductName(value ? value : "")
    else if(field === "productStatus")
      setFilterByStatus(value ? value : "")
    setPage(10)
  }

  const { downloadCSVcallback } = useDownloadCSV(`${fetchUrl}.csv`, (new Date()).toString(),'_brands_delta.csv')

  const updateToDate = (value: string | undefined) => {
    if (value) {
      setToDate(value)
    }
  }

  const updateFromDate = (value: string | undefined) => {
    if (value) {
      setFromDate(value)
    }
  }

  const handleSearch = (selectedKeys: any, confirm: any, dataIndex: any) => {
    confirm();
  };

  const handleReset = (clearFilters: any) => {
    clearFilters();
    setPage(10)
  };

  const getColumnSearchProps = (dataIndex: any) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }:{setSelectedKeys: any, selectedKeys: any,confirm:any, clearFilters:any}) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys?selectedKeys[0]:""}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            size="small"
            style={{ width: 90, padding: 0 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
      </div>
    ),
    filterIcon: (filtered:any) => <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value: string, record: any) => {
      
      return true;
    },
    onFilterDropdownVisibleChange: (visible: boolean) => {
      if (visible) {
        // setTimeout(() => searchInput.select());
      }
    },
    render: (text: any) =>
      (
        text
      ),
  });

  const columns = [
        {
          title: 'Product Id',
          dataIndex: 'productId',
          key: 'productId',
          ...getColumnSearchProps('productId'),
          width:120,
        },
        {
          title: 'Aggregator Code',
          dataIndex: 'aggregatorCode',
          key: 'aggregatorCode',
          width:120,
        },
        {
          title: 'Product Name',
          dataIndex: 'productName',
          key: 'productName',
          ...getColumnSearchProps('productName'),
          width:150,   
        },
        {
          title: 'Country Name',
          dataIndex: 'countryName',
          key: 'countryName',
          width:120,
        },
        {
          title: "Discount",
          dataIndex: 'discount',
          key: 'product_discount',
          width: 100,
          render: (response: string) => {
            return (+response).toFixed(2)
          },
        },
        {
          title: 'Status',
          dataIndex: 'status',
          key: 'productStatus',
          sorter: (a: any, b: any) => { return 0},
          filters: [
            {
              text: 'Added',
              value: 'Added',
            },
            {
              text: 'Removed',
              value: 'Removed',
            }
          ],
          onFilter: (value: any, record: any) => true,
          filterMultiple: false,
          width:100
        },
        {
          title: 'Date',
          dataIndex: 'created_at',
          key: 'created_at',
          align: 'right' as 'right',
          width:100,
          sorter: (a: any, b: any) => { return 0 }
        }
      ] 
      console.log('brands', brands);
      return (
        <CatalogContainer>
          <SectionHeader>
            <SectionTitle>
              Catalog Delta
            </SectionTitle>
            <Filters>
              <DatePicker
                allowClear={false}
                defaultValue={Moment(fromDate).utc()}
                format={dateFormat}
                disabledDate={disabledStartDate} 
                onChange={date => updateFromDate(date?.utc().startOf('day').format())}
                data-testid='test-from-date'
              />
              <DatePicker
                allowClear={false}
                defaultValue={Moment(toDate).utc()}
                format={dateFormat}
                disabledDate={disabledDate} 
                onChange={date => updateToDate(date?.utc().endOf('day').format())}
                data-testid='test-to-date'
              />

              <Button
                type='default'
                shape='round'
                onClick={() => downloadCSVcallback(fromDate, toDate)}
              >
                <Icon type='download' />
                Download CSV
              </Button>

            </Filters>
          </SectionHeader>
        <ContentContainer>
          <PaginatedTable
            total={totalCount}
            loading={false}
            columns={columns}
            data={brands}
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
            currentPage={currentPage}
            pageSize={pageSize}
            onSortChange={onSortChange}
            onFilterApplied={onFilterApplied}
            rowKey='id'
            // displayContainer={displayContainer}
          />
        </ContentContainer>
        </CatalogContainer>
      )
}

export default CatalogAudit
