import React, { useContext, useEffect, useState } from 'react'
import { Row, Tooltip, Icon, Modal } from 'antd'
import { Input } from 'common/components/Input'
import { StyledInput, Label, Box } from '../../styledComponents'
import CopyToClipboard from 'common/components/CopyToClipBoard'
import ViewContainer from 'common/components/ViewContainer'
import { client } from 'common/api/client'
import { openNotification } from 'common/components/openNotification'
import phazeEnvContext, { PhazeEnv } from 'common/contexts/phazeEnvContext'
import './styles.css'
import fileDownload from 'js-file-download'

interface ISettingsProps {
  fetchConfigurations: () => Promise<void>
  apiKey: string
  requestSecret: string
}

const Keys: React.FC<ISettingsProps> = ({
  fetchConfigurations,
  apiKey,
  requestSecret,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [howToUse, setHowToUse] = useState<{
    subject: string
    headerMessage: string
    from: string
    bccEmail: string
    phazeApiWorkflowImage: string
    jsonFile: string
  }>()
  const { phazeEnv } = useContext(phazeEnvContext)

  const openModal = () => {
    setIsModalVisible(true)
  }

  useEffect(() => {
    client
      .get('/partner-onboarding-info')
      .then((r) => {
        setHowToUse(r.data)
      })
      .catch((e) => {
        openNotification(
          e.response?.data?.message || 'Something went wrong',
          'error'
        )
      })
  }, [])

  useEffect(() => {
    fetchConfigurations()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const isStagingOrSandbox =
    PhazeEnv.Sandbox === phazeEnv || PhazeEnv.Staging === phazeEnv

  const downloadJsonHandler = (url: string) => {
    client
      .get(url)
      .then((r) => {
        const data = JSON.stringify(r.data)

        fileDownload(data, 'phaze-sandbox-empty-postman-environment.json')
      })
      .catch((e) => {
        openNotification(
          e.response?.data?.message || 'Something went wrong',
          'error'
        )
      })
  }

  return (
    <ViewContainer title='API keys'>
      <Box>
        {isStagingOrSandbox && (
          <Row
            type='flex'
            justify='space-between'
            style={{ placeItems: 'end' }}
          >
            <div></div>
            <div>
              {/* <ExclamationCircleOutline /> */}

              <Tooltip placement='top' title='How to use!'>
                <Icon
                  style={{
                    fontSize: '22px',
                    cursor: 'pointer',
                    userSelect: 'none',
                  }}
                  type='exclamation-circle'
                  onClick={openModal}
                />
              </Tooltip>
              <Modal
                title='How to use'
                visible={isModalVisible}
                onOk={() => setIsModalVisible(false)}
                onCancel={() => setIsModalVisible(false)}
                width={'100%'}
                style={{
                  maxWidth: '800px',
                  padding: '1rem',
                }}
              >
                <div
                  // source is trusted
                  dangerouslySetInnerHTML={{ __html: howToUse?.subject || '' }}
                ></div>

                <hr />

                <h3>Attachments</h3>
                <div className='attachment-container'>
                  <a
                    target={'_blank'}
                    href={howToUse?.phazeApiWorkflowImage || ''}
                    rel='noopener noreferrer'
                    download
                  >
                    <img
                      className='attachment'
                      src={howToUse?.phazeApiWorkflowImage}
                      alt='workflow'
                    />
                  </a>

                  <div
                    className='attachment download-json'
                    onClick={() =>
                      howToUse && downloadJsonHandler(howToUse.jsonFile)
                    }
                  >
                    phaze-sandbox-empty-postman-environment.json
                  </div>
                </div>
              </Modal>
            </div>
          </Row>
        )}
        <Row type='flex' justify='space-between' style={{ placeItems: 'end' }}>
          <Input.Group style={{ width: '90%' }}>
            <Label style={{ marginLeft: 0 }}>API Key</Label>
            <StyledInput disabled value={apiKey || ''} placeholder='API Key' />
          </Input.Group>
          {apiKey && (
            <>
              <CopyToClipboard
                text={apiKey}
                successMessage='Copied API Key to clipboard'
              />
            </>
          )}
        </Row>
        <Row
          type='flex'
          justify='space-between'
          style={{ placeItems: 'end', marginTop: 10 }}
        >
          <Input.Group style={{ width: '90%' }}>
            <Label style={{ marginLeft: 0 }}>Secret</Label>
            <StyledInput
              disabled
              value={requestSecret || ''}
              placeholder='Secret'
            />
          </Input.Group>
          {requestSecret && (
            <CopyToClipboard
              text={requestSecret}
              successMessage='Copied Secret to clipboard'
            />
          )}
        </Row>
      </Box>
    </ViewContainer>
  )
}
export default Keys
