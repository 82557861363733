import { useCallback, useState } from 'react'
import { client } from 'common/api/client'
import FileDownload from 'js-file-download'
import { openNotification } from 'common/components/openNotification'

const useDownloadCSV = (url: string, organizationName?:string, name? : string) => {
  const [isLoading, setIsLoading] = useState(false)
  const [fname] = useState('_transaction_history.csv')

  const downloadCSVcallback = useCallback(
    (fromDate, toDate) => {
      setIsLoading(true)

      const fetchData = async (url: string) => {
        try {
          const response = await client.get(url, {
            params: {
              fromDate: fromDate,
              toDate: toDate
            }
          })

          let fileName= organizationName + fname
          if(name)
            fileName = organizationName + name

          FileDownload(response.data, fileName)
        } catch (err) {
          const errorMessage = err.response?.data.error || err.toString()
          openNotification(errorMessage, 'error')
        }
        setIsLoading(false)
      }

      fetchData(url)
    },
    [organizationName, url,fname,name]
  )

  return {
    isLoading,
    downloadCSVcallback
  }
}

export default useDownloadCSV
