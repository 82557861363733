import { useEffect, useState } from 'react'
import { client } from 'common/api/client'
import { Transaction } from 'common/store/schema/transactions'

const useFetchTransactions = (
  url: string,
  currentPage: number,
  perPage: number,
  fromDate: string,
  toDate: string,
  sortBy: string,
  order: string,
  filterByBrand: string | null,
  filterByOrderId: string | null,
  filterByStatus: string | null,
  filterByPartnerName: string | null,
  filterByClientProductId: string | null,
  filterBySupplierProductId: string | null,
  filterByExternalUserId: string | null,
) => {
  const [data, setData] = useState<Transaction[]>([])
  const [total, setTotal] = useState(0)
  const [lastV1TransactionTime, setLastV1TransactionTime] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(false)

  useEffect(() => {
    setError(false)
    setIsLoading(true)
    const fetchData = async () => {
      try {
        const response = await client.get<{
          result: Transaction[]
          totalCount: number
          lastV1TransactionTime: string
        }>(url, {
          params: {
            currentPage,
            perPage,
            fromDate,
            toDate, sortBy, order, filterByBrand, filterByOrderId, filterByStatus, 
            filterByPartnerName, filterBySupplierProductId, filterByClientProductId, 
            filterByExternalUserId
          }
        })
        setData(response.data.result)
        setTotal(response.data.totalCount)
        setLastV1TransactionTime(response.data.lastV1TransactionTime)
      } catch (error) {
        setError(true)
      }
      setIsLoading(false)
    }

    fetchData()
  }, [url, currentPage, perPage, fromDate, toDate, sortBy, order, filterByBrand, filterByOrderId, 
      filterByStatus, filterByPartnerName, filterBySupplierProductId, filterByClientProductId, filterByExternalUserId])

  return { data, isLoading, error, total, lastV1TransactionTime }
}

export default useFetchTransactions
