import React from 'react'
import { Icon } from 'antd'
import CompanyInformationForm from '../CompanyInformationForm'
import ConfigureForm from '../ConfigureForm'
import AccessKeys from '../AccessKeys'
import WebHooks from '../WebHooks'
import SaaSSubscription from '../SaaSSubscription'

export const tabs = [
  {
    key: 'companyInfo',
    labelContent: (
      <>
        <Icon type='shop' />
        Company Information
      </>
    ),
    bodyContent: <CompanyInformationForm />
  },
  {
    key: 'configure',
    labelContent: (
      <>
        <Icon type='tool' />
        Configure
      </>
    ),
    bodyContent: <ConfigureForm withoutInitialFunding />
  },
  {
    key: 'topUpSaaSSubscription',
    labelContent: (
      <>
        <Icon type='tool' />
        TopUp SaaS Subscription
      </>
    ),
    bodyContent: <SaaSSubscription topUpSaaSSubscriptionDisplay />
  },
  {
    key: 'brandSaaSSubscription',
    labelContent: (
      <>
        <Icon type='tool' />
        Brand SaaS Subscription
      </>
    ),
    bodyContent: <SaaSSubscription />
  },
  {
    key: 'accessKeys',
    labelContent: (
      <>
        <Icon type='database' />
        Access Keys
      </>
    ),
    bodyContent: <AccessKeys />
  },
  {
    key: 'webhooks',
    labelContent: (
      <>
        <Icon type='database' />
          Webhooks
      </>
    ),
    bodyContent: <WebHooks />
  }
]

const fallbackCompanyValues = {
  addressOne: '',
  addressTwo: '',
  city: '',
  country: 'CA',
  organizationName: '',
  province: '',
  postalCode: '',
  website: '',
  cryptoCompany: false,
  enableRewardsPage: false,
  reconcilePartner: false,
}

const fallbackConfigureValues = {
  prefundedAmountWarningTopupAmount: '',
  prefundedAmountWarningLow: '',
  fxSpreadPercent: '',
  topUpFxSpreadPercent: '',
  marginOffset: '',
  transactionFeeType: '',
  transactionFee: '',
  openLoopTransactionFeeType: '',
  openLoopTransactionFee: '',
  topupWarningEmails: '',
  supportEmail: '',
  breakageSplitPercent: '',
  transactionExpiryInMonth: ''
}

export const fallbackFormValues = {
  ...fallbackCompanyValues,
  ...fallbackConfigureValues
}
