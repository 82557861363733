import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { State } from '../rootReducer'
import { ProductListType } from '../schema/productList'

type ProductListState = {
  loading: boolean
  error: string | null
  brandList: ProductListType[]
  totalCount: number
}

const initialState: ProductListState = {
  loading: false,
  error: null,
  brandList: [],
  totalCount: 0
}

function startLoading(state: ProductListState) {
  state.loading = true
  state.error = null
}

function loadingFailed(state: ProductListState, action: PayloadAction<string>) {
  state.loading = false
  state.error = action.payload
}

const ProductListSlice = createSlice({
  name: 'ProductList',
  initialState,
  reducers: {      
    fetchBrandForSelectedTypeRequest: startLoading,
    fetchBrandForSelectedTypeFailure: loadingFailed,
    fetchBrandForSelectedTypeSuccess(
      state,
      action: PayloadAction<{ result: ProductListType[]; totalCount: number }>
    ) {
      state.loading = false
      state.error = null
      state.brandList = action.payload.result
      state.totalCount = action.payload.totalCount
    },
    deleteProductRequest: startLoading,
    deleteProductFailure: loadingFailed,
    deleteProductSuccess(state) {
      state.loading = false
      state.error = null
    },
    addProductRequest: startLoading,
    addProductSuccess (state) {
      state.loading = false
      state.error = null
    },
    addProductFailure: loadingFailed,
  }
})

const getProductList = (state: State) => state.productList.brandList
const getIsLoading = (state: State) => state.productList.loading
const getTotalCount = (state: State) => state.productList.totalCount

export const ProductListSelectors = {
  getProductList,
  getIsLoading,
  getTotalCount
}

export const {
  fetchBrandForSelectedTypeRequest,
  fetchBrandForSelectedTypeSuccess,
  fetchBrandForSelectedTypeFailure,
  deleteProductRequest,
  deleteProductSuccess,
  deleteProductFailure,
  addProductRequest,
  addProductSuccess,
  addProductFailure
} = ProductListSlice.actions

export const ProductListReducer = ProductListSlice.reducer
