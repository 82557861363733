import React, { useEffect, useMemo, useState } from 'react'
import { ContentContainer } from 'common/components/ContentContainer'
import PaginatedTable from 'common/components/PaginatedTable'
import { RouteComponentProps } from 'react-router-dom'
import {
  AggregatorsContainer,
  SectionHeader,
  SectionTitle
} from './styledComponents'
import { ColumnProps } from 'antd/lib/table'
import { ActionsContainer } from 'core/Partners/styledComponents'
import { Select } from 'common/components/Select'

const { Option } = Select

interface ErrorLogsProps {
  errorLogsArray: Array<any>
  totalErrors: number
  account: Array<{ organizationName: string; id: number }>
  fetchErrorLogs: (
    currentPage: number,
    perPage: number,
    accountId: number | undefined,
    order: string
  ) => void
}

const ErrorLogs: React.FC<ErrorLogsProps & RouteComponentProps> = ({
  errorLogsArray,
  totalErrors,
  fetchErrorLogs,
  account
}) => {
  const [params, setParams] = useState({
    currentPage: 1,
    pageSize: 10,
    accountId: 0,
    order: 'DESC'
  })

  useEffect(() => {
    //Fetch the error logs
    fetchErrorLogs(params.currentPage, params.pageSize, params.accountId,params.order)
  }, [fetchErrorLogs, params])

  const handlePageChange = (pageNumber: number) =>
    setParams({ ...params, currentPage: pageNumber })

  const handlePageSizeChange = (perPage: number) => {
    setParams({ ...params, currentPage: 1, pageSize: perPage })
  }

  const onSortChange = (_: string, order: string | null) => {
    setParams({ ...params, order: order === 'DESC' ? 'DESC' : 'ASC' })
  }

  const onFilterApplied = (
    filterField: string | null,
    filterValue: string | null
  ) => {
    setParams({ ...params, currentPage: 1 })
  }

  const columns = useMemo<ColumnProps<any>[]>(
    () => [
      {
        title: 'id',
        key: 'id',
        dataIndex: 'id',
        width: 150
      },
      {
        title: 'Name',
        key: 'name',
        dataIndex: 'name',
        width: 200
      },
      {
        title: 'Type',
        key: 'type',
        dataIndex: 'type',
        width: 150
      },
      {
        title: 'Date At',
        key: 'dateAt',
        dataIndex: 'dateAt',
        width: 360,
        sorter: true
      },
      {
        title: 'EndPoint Details',
        key: 'endPointDetails',
        dataIndex: 'endPointDetails',
        render: (data: any) => {
          return JSON.stringify(data)
        },
        width: 360
      },
      {
        title: 'Request Headers',
        key: 'requestHeaders',
        dataIndex: 'requestHeaders',
        width: 360,
        render: (data: any) => {
          return JSON.stringify(data)
        }
      },
      {
        title: 'Error Message',
        key: 'errorMessage',
        dataIndex: 'errorMessage',
        width: 360
      }
    ],
    []
  )

  const handleAccountSelection = (value: unknown) => {
    if (value && typeof value == 'number') {
      setParams({ ...params, pageSize: 10, currentPage: 1, accountId: value })
    } else if (value === '0') {
      setParams({ ...params, pageSize: 10, currentPage: 1, accountId: 0 })
    }
  }

  return (
    <AggregatorsContainer>
      <SectionHeader>
        <SectionTitle>Error Logs</SectionTitle>
        <ActionsContainer>
          <Select
            onChange={handleAccountSelection}
            defaultValue='Select All Partner'
            style={{ width: 350 }}
          >
            <Option value='0' key='0' data-testid='test-custom--month-range-0'>
              Select All Partner
            </Option>
            {account.map(acc => (
              <Option
                value={acc.id}
                key={acc.id}
                data-testid='test-custom--month-range-{{acc.id}}'
              >
                {acc.organizationName}
              </Option>
            ))}
          </Select>
        </ActionsContainer>
      </SectionHeader>
      <ContentContainer>
        <PaginatedTable
          total={totalErrors}
          loading={false}
          data={errorLogsArray}
          columns={columns}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          currentPage={params.currentPage}
          pageSize={params.pageSize}
          onSortChange={onSortChange}
          onFilterApplied={onFilterApplied}
          rowKey='dateAt'
        />
      </ContentContainer>
    </AggregatorsContainer>
  )
}

export default ErrorLogs
