import React, { useEffect, useState } from 'react'

import Icon from 'antd/lib/icon'
import { Button } from 'common/components/Button'
import { Empty } from 'common/components/Empty'
import { Input } from 'common/components/Input'
import { InputIcon } from 'common/components/InputIcon'
import ViewContainer from 'common/components/ViewContainer'
import { ContentContainer } from 'common/components/ContentContainer'
import { ActionsContainer } from './styledComponents'

import PaginatedTable from 'common/components/PaginatedTable'
import {
  TodaySection,
  SectionHeader,
} from '../Dashboard/styledComponents'
import { ActionButton, Actions } from './styledComponents'
import { Modal } from 'antd'
import AddProductBasedOnType from './AddBrandList'
import { ProductListType } from 'common/store/schema/productList'
import { BrandCountries } from 'common/store/schema/app'

const { confirm } = Modal

type BrandListBasedOnTypeProps = {
  brandList: ProductListType[],
  brandCountryList: Array<BrandCountries>,
  fetchBrandForSelectedType: (
    brandListType : string,
    currentPage?: number,
    perPage?: number,
    productSearch?: string,
  ) => Promise<void>
  isLoading: boolean
  totalList: number,
  brandListType: string,
  deleteProduct: (id: string,brandListType : string,currentPage? : number, perPage? : number, search? : string) => void
  addProduct: (updatedValue: any,brandListType: string,currentPage? : number, perPage? : number, search? : string) => void
}

const BrandListBasedOnType: React.FC<BrandListBasedOnTypeProps> = ({
  brandList,
  fetchBrandForSelectedType,
  isLoading,
  totalList,
  deleteProduct,
  brandCountryList,
  brandListType,
  addProduct
}) => {

  console.log(brandList)

  const [search, setSearch] = useState('')
  const [currentPage, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)

  useEffect(() => {
    fetchBrandForSelectedType(brandListType,currentPage, perPage, search)
  }, [fetchBrandForSelectedType, currentPage, perPage, search,brandListType])

  const onPageChange = (currentPage: number) => {
    setPage(currentPage)
  }

  const onPageSizeChange = (value: number) => {
    setPerPage(value)
    setPage(1)
  }

  const updateSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value.trim())
    setPage(1)
  }

  const onSortChange = (sortBy: string, order: string | null) => {
  }

  const onFilterApplied = (actionName: string | null) => {
  }

  const showDeleteConfirmation = (id: string, productId?: string, productName ?: string) =>{
    const title = (id === 'all') ? 'Are you sure you want to delete all the records?'  : 
                'Are you sure you want to delete the product from exclsionList?'
    const content = (id === 'all') ? ''  : 
                                `productId: ${productId}`

    confirm({
      title: title,
      content: content,
      okText: 'Yes',
      onOk() {
        deleteProduct(id,brandListType,currentPage,perPage,search)
      }
    })
  }

  const addProductToTheList = (updatedobject: any) =>{
    addProduct(updatedobject,brandListType,currentPage,perPage,search)
  }

  let columns = [];
  let title = 'Persistent List'
  
  if(brandListType === "exclusion"){
    title = 'Exclusion List'
    columns = [
      {
        title: 'ProductId',
        dataIndex: 'productId',
        key: 'product_id'
      },
      {
        title: 'Product Name',
        dataIndex: 'productName',
        key: 'productName'
      },
      {
        title: 'Product Discount',
        dataIndex: 'productDiscount',
        key: 'productDiscount'
      },
      {
        title: 'Country',
        dataIndex: 'productCountryName',
        key: 'productCountryName'
      },
      {
        title: 'Country Code',
        dataIndex: 'productCurrencyCode',
        key: 'productCurrencyCode'
      },
      {
        title: 'Aggregator Name',
        dataIndex: 'aggregatorName',
        key: 'aggregatorName'
      },  
      {
        title: 'Action',
        key: 'action',
        align: 'center' as 'center',
        render: (text: string, record: ProductListType) => {
            return (
              <Actions>
                  <>
                    <ActionButton
                      onClick={() => showDeleteConfirmation(record.id.toString(),record.productId,record.productName)}
                      type='link'
                    >
                      Delete
                    </ActionButton>
                  </>
                
              </Actions>
            )
        }
      }
    ]
  }else{
    columns = [
      {
        title: 'Client Facing ProductId',
        dataIndex: 'clientFacingProductId',
        key: 'clientFacingProductId'
      },
      {
        title: 'Supplier Facing ProductId',
        dataIndex: 'productId',
        key: 'product_id'
      },
      {
        title: 'Supplier Product Name',
        dataIndex: 'productName',
        key: 'productName'
      },
      {
        title: 'Supplier Product Discount',
        dataIndex: 'productDiscount',
        key: 'productDiscount'
      },
      {
        title: 'Supplier Country',
        dataIndex: 'productCountryName',
        key: 'productCountryName'
      },
      {
        title: 'Supplier Country Code',
        dataIndex: 'productCurrencyCode',
        key: 'productCurrencyCode'
      },
      {
        title: 'Supplier Aggregator Name',
        dataIndex: 'aggregatorName',
        key: 'aggregatorName'
      },  
      {
        title: 'Action',
        key: 'action',
        align: 'center' as 'center',
        render: (text: string, record: ProductListType) => {
            return (
              <Actions>
                  <>
                    <ActionButton
                      onClick={() => showDeleteConfirmation(record.id.toString(),record.productId,record.productName)}
                      type='link'
                    >
                      Delete
                    </ActionButton>
                  </>
                
              </Actions>
            )
        }
      }
    ]
  }


  return (
    <ViewContainer title={title}>
      {brandList.length > 0 || search ? (
        <>
        <TodaySection>
          <SectionHeader>
            <AddProductBasedOnType brandCountryList={brandCountryList} brandListType={brandListType}
              addProductToTheList = {addProductToTheList}
            />
            <ActionsContainer>
              <Button
                type='default'
                shape='round'
                style={{ margin: '0 20px 0 0' }}
                onClick={() => showDeleteConfirmation('all')}
              >
                <Icon type='delete' />
                Delete All
              </Button>
                <Input
                  placeholder='search by productId or product name'
                  onChange={updateSearch}
                  style={{ width: 350 }}
                  prefix={<InputIcon type='search' />}
                />
            </ActionsContainer>
            </SectionHeader>
              <ContentContainer>
                <PaginatedTable
                  total={totalList}
                  loading={isLoading}
                  data={brandList}
                  columns={columns}
                  onPageChange={onPageChange}
                  onPageSizeChange={onPageSizeChange}
                  currentPage={currentPage}
                  pageSize={perPage}
                  onSortChange={onSortChange}
                  onFilterApplied={onFilterApplied}
                  rowKey= 'productId'
                />
              </ContentContainer>
          </TodaySection>
        </>
      ) : (
        <ContentContainer>
          <Empty description='No products at the moment.'>            
            <AddProductBasedOnType brandCountryList={brandCountryList} addProductToTheList = {addProductToTheList}
               brandListType={brandListType} />
          </Empty>
        </ContentContainer>
      )}
    </ViewContainer>
  )
}

export default BrandListBasedOnType
