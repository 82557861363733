import React, { useEffect } from 'react'
import { Avatar, Dropdown, Icon, Menu } from 'antd'
import {
  Logo,
  HeaderContainer,
  Username,
  DropDownTrigger,
  UserSettings
} from './styledComponents'
import EnvSwitcher from './EnvSwitcher'
import PhazeLogo from 'assets/logo.png'
import { History } from 'history'

interface HeaderProps {
  logout: (history: History) => void
  history: History
  email: string
  getUserInfo: () => void
}

const Header: React.FC<HeaderProps> = ({
  logout,
  history,
  email,
  getUserInfo
}) => {
  useEffect(() => {
    if (!email) {
      getUserInfo()
    }
  }, [email, getUserInfo])

  const menu = (
    <Menu>
      <Menu.Item key='1' onClick={() => logout(history)}>
        Logout
      </Menu.Item>
    </Menu>
  )

  return (
    <HeaderContainer>
      <Logo src={PhazeLogo} alt='company logo' />
      <UserSettings>
        <EnvSwitcher />
        <Avatar size={40} icon='user' />
        <Dropdown overlay={menu} overlayStyle={{ width: 130 }}>
          <DropDownTrigger data-testid='test-user-settings'>
            <Username>{email}</Username> <Icon type='down' />
          </DropDownTrigger>
        </Dropdown>
      </UserSettings>
    </HeaderContainer>
  )
}

export default Header
