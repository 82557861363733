import React from 'react'
import { Actions, ActionButton } from './styledComponents'
import PaginatedTable from 'common/components/PaginatedTable'

import { User } from 'common/store/schema/team'
import Moment from 'moment'

interface UsersTableProps {
  users: User[]
  activeUser: string
  showDeleteUserConfirmation: (username: string, email: string, userId: number) => void
  editTeamMember: (userId: number) => void
  onPageSizeChange: (value: number) => void
  onPageChange: (currentPage: number) => void
  onFilterApplied: (filterField: string | null, filterValue: string | null) => void
  onSortChange: (sortBy: string, order: string | null) => void
  currentPage: number
  perPage: number
  totalTeamMember: number
}

const UsersTable: React.FC<UsersTableProps> = ({
  users,
  activeUser,
  showDeleteUserConfirmation,
  editTeamMember,
  onPageSizeChange,
  onPageChange,
  onFilterApplied,
  onSortChange,
  currentPage,
  perPage,
  totalTeamMember
}) => {
  

  let partnerFilter: Array<{text : string , value: string }> = [];
  let adminName: Array<{text : string , value: string }> = [];
  
  users.forEach( (res : any) => {
    if(res.account)
      partnerFilter.push({ text : res.account.organizationName , value: res.account.organizationName })
    if(res.firstName)
      adminName.push({text : res.firstName+ ' ' + res.lastName , value:  res.firstName+ ' ' + res.lastName })
  })

  partnerFilter = partnerFilter.filter((v,i,a)=>a.findIndex(t=>(t.text === v.text))===i)
  adminName = adminName.filter((v,i,a)=>a.findIndex(t=>(t.text === v.text))===i)

  const displayContainer = false

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text: string, record: User) => {
        return `${record.firstName || ''} ${record.lastName || ''}`
      },      
      filters: adminName,
      onFilter: (value: any, record: any) => true,
      filterMultiple: false,
      sorter: (a: any, b: any) => { return 0},
    },
    {
      title: 'Partner',
      dataIndex: 'partner',
      key: 'partner',
      sorter: (a: any, b: any) => { return 0},
      render: (text: string, record: User) => {
        return record.account ? record.account.organizationName : ''
      },
      filters: partnerFilter,
      onFilter: (value: any, record: any) => true,
      filterMultiple: false,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'User Type',
      dataIndex: 'role',
      key: 'role',
      filters: [
        {
          text: 'Partner Admin',
          value: 'partner_admin',
        },
        {
          text: 'Super Admin',
          value: 'super_admin',
        }
      ],
      onFilter: (value: any, record: any) => true,
      filterMultiple: false,
    },
    {
      title: 'Last Logged In',
      dataIndex: 'lastLoggedIn',
      key: 'lastLoggedIn',
      sorter: (a: any, b: any) => { return 0},
      render: (lastLoggedIn: string) =>
        lastLoggedIn ? (
          <>
            {Moment(lastLoggedIn).calendar(undefined, {
              sameDay: '[Today]',
              lastDay: '[Yesterday]',
              lastWeek: 'MMM D, YYYY',
              sameElse: 'MMM D, YYYY'
            })}
          </>
        ) : (
          ''
        )
    },
    {
      title: 'Action',
      key: 'action',
      render: (text: string, record: User) => {
        const username = `${record.firstName} ${record.lastName}`
        const email = `${record.email}`
        return (
          <Actions>
           <>
              <ActionButton
                    type='link'  onClick={() => editTeamMember(record.id)}
              >
                    Edit
              </ActionButton>
            </>
            {activeUser !== record.email && (
              <>
                <span>|</span>
                <ActionButton
                  onClick={() => showDeleteUserConfirmation(username,email,record.id)}
                  type='link'
                >
                  Delete
                </ActionButton>
              </>
            )}
          </Actions>
        )
      }
    }
  ]

  return (
    <PaginatedTable
        total={totalTeamMember}
        loading={false}
        columns={columns}
        data={users}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        currentPage={currentPage}
        pageSize={perPage}
        onSortChange={onSortChange}
        onFilterApplied={onFilterApplied}
        rowKey='email'
        displayContainer={displayContainer}
      />
  )
}

export default UsersTable
